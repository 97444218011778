<template>
    <div>
        <div class="two-factor-setup">
            <div class="two-factor-setup__text">{{ consts.setupInstruction }}</div>
            <div class="two-factor-setup__flex-container">
                <div class="two-factor-setup__item">
                    <div>{{ consts.scanQRCode }}</div>
                    <img v-if="setupInfo" class="two-factor-setup__qr-image" :src="imageSrc" />
                    <div></div>
                </div>
                <vertical-divider :text="consts.or"></vertical-divider>
                <div class="two-factor-setup__item">
                    <div>{{ consts.manualCode }}</div>
                    <div v-if="setupInfo" class="two-factor-setup__manual-key">{{ setupInfo.manualEntryKey }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import VerticalDivider from '@/components/common/VerticalDivider';
import twoFactorAuthenticationConsts from '@/consts/twoFactorAuthenticationConsts';
import { mapState } from 'vuex';
import dataToBlobService from '@/lib/dataToBlobService';
export default {
    components: {
        VerticalDivider
    },
    data() {
        return {
            consts: twoFactorAuthenticationConsts,
            imageSrc: ''
        };
    },
    computed: {
        ...mapState('twoFactor', {
            setupInfo: (state) => {
                return state.setupInfo;
            }
        })
    },
    mounted() {
        if (this.setupInfo.qrCodeImageUrl) {
            this.imageSrc = dataToBlobService.dataToBlob(this.setupInfo.qrCodeImageUrl);
        }
    }
};
</script>
<style lang="scss">
.two-factor-setup {
    &__text {
        padding-bottom: 2rem;
    }
    &__flex-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__item {
        margin: auto;
        width: 10rem;
    }
    &__qr-image {
        margin: 1rem auto;
        width: 7rem;
    }
    &__manual-key {
        background-color: $gallery;
        margin: 1.5rem auto;
        min-height: 5rem;
        overflow-wrap: break-word;
        padding: 0.5rem;
        text-align: left;
    }
}
</style>
