<template>
    <div class="divider-container">
        <div class="divider-container__divider"></div>
        <div class="divider-container__text">{{ text }}</div>
        <div class="divider-container__divider"></div>
    </div>
</template>
<script>
export default {
    props: {
        text: {
            type: String,
            default: 'Or'
        }
    }
};
</script>
<style lang="scss" scoped>
.divider-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    &__divider {
        background-color: $silver;
        height: 100%;
        width: 1px;
    }
    &__text {
        margin: 0.5rem 0;
    }
}
</style>
