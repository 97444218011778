function dataToBlob(dataURI) {
    const parts = dataURI.split(',');
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(parts[1]);
    // separate out the mime component
    const [mimeString] = parts[0].split(':')[1].split(';');
    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);
    // create a view into the buffer
    // eslint-disable-next-line compat/compat
    const ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    const urlCreator = URL || window.webkitURL;
    const objectUrl = urlCreator.createObjectURL(blob);
    return objectUrl;
}

export default { dataToBlob };
